// 设备分类接口
import {
	axios
} from '../utils/http.js'
const DeviceApi = {
	DeviceList: `/DeviceClass/List`,//设备分类列表
	DeviceAdd: '/DeviceClass/Add',//添加设备分类
	DeviceUp: '/DeviceClass/Up',//编辑设备分类
	DeviceDel: '/DeviceClass/Del',//设备分类-删除
	DeviceClassList: '/DeviceClassList/List',//设备分类-设备管理-设备列表
	DeviceClassDel: '/DeviceClassList/Del',//设备分类-设备管理-设备列表删除
	DeviceClassAllAdd: '/DeviceClassList/Add',//批量添加
	DeviceClassUp: '/DeviceClassList/Up',//修改T_id
}

//设备分类列表
export function DeviceList(data) {
	return axios({
		url: DeviceApi.DeviceList,
		method: 'post',
		data:data
	})
}

//添加设备分类
export function DeviceAdd(data) {
	return axios({
		url: DeviceApi.DeviceAdd,
		method: 'post',
		data: data
	})
}

//添加设备分类
export function DeviceUp(data) {
	return axios({
		url: DeviceApi.DeviceUp,
		method: 'post',
		data: data
	})
}
//设备分类-删除
export function DeviceDel(data) {
	return axios({
		url: DeviceApi.DeviceDel,
		method: 'post',
		data: data
	})
}

//设备分类-设备管理-设备列表
export function DeviceClassList(data) {
	return axios({
		url: DeviceApi.DeviceClassList,
		method: 'post',
		data: data
	})
}
//设备分类-设备管理-设备列表删除
export function DeviceClassDel(data) {
	return axios({
		url: DeviceApi.DeviceClassDel,
		method: 'post',
		data: data
	})
}
//批量添加
export function DeviceClassAllAdd(data) {
	return axios({
		url: DeviceApi.DeviceClassAllAdd,
		method: 'post',
		data: data
	})
}
//批量添加
export function DeviceClassUp(data) {
	return axios({
		url: DeviceApi.DeviceClassUp,
		method: 'post',
		data: data
	})
}