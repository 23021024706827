<template>
	<div class="ClassifyList">
		<div class="ClassifyList1">
			<div class="ClassifyList1_1">
				<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入设备内容搜索" v-model="Pages.T_name"
					clearable @keyup.enter.native="searchValue"></el-input>
				<el-button type="primary" icon="el-icon-search" style="margin: 0 10px;" @click="searchValue()">搜索
				</el-button>
			</div>
			<div class="ClassifyList1_2" @click="Newzeng()">
				<el-button type="primary" icon="el-icon-plus">添加分类</el-button>
			</div>
		</div>
		<div class="ClassifyList2">
			<el-table :data="tableData" style="width: 100%" border>
				<el-table-column property="Id" label="ID" width="70"></el-table-column>
				<el-table-column label="库房名称">
					<template slot-scope="scope">
						<i class="el-icon-odometer" style="font-size: 20px;"></i>
						<span style="margin-left: 10px">{{ scope.row.T_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary" icon="el-icon-setting" size="mini" @click="Compiles(scope.row)">编辑
						</el-button>
						<el-button type="primary" icon="el-icon-edit-outline" size="mini" @click="Lists(scope.row.Id)">
							设备管理</el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini" @click="Deletes(scope.row.Id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!--编辑、新增-->
		<el-dialog :title="titles" :visible.sync="ifyList" width="30%" top="0">
			<el-form :model="form" :rules="rules" ref="ruleForm">
				<el-form-item label="分类名称" prop="name">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="ifyList = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>

		<!--设备列表-->
		<el-dialog title="设备列表" :visible.sync="ifyShebei" top="0">
			<div class="ClassifyList1" style="margin-bottom: 20px;">
				<div class="ClassifyList1_1" style="min-width:150px;max-width:400px;">
					<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入设备SN搜索"
						v-model="DeviceClass.T_sn" clearable @keyup.enter.native="searchDCValue"></el-input>
					<el-button type="primary" icon="el-icon-search" style="margin: 0 10px;" @click="searchDCValue()">搜索
					</el-button>
				</div>
				<div class="ClassifyList1_2" @click="innerVisible=true">
					<el-button type="primary" icon="el-icon-plus" style="">批量导入
					</el-button>
				</div>
			</div>
			<el-table :data="gridData" border>
				<el-table-column property="T_id" label="编号" width="70"></el-table-column>
				<el-table-column property="T_sn" label="设备SN"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary" icon="el-icon-setting" size="mini"
							@click="DeviceClassDelBianji(scope.row)">编辑</el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="DeviceClassDelDeletes(scope.row.Id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background @current-change="DeviceClassChange" :page-size="DeviceClass.page_z"
				layout="total, prev, pager, next, jumper" :total="DeviceClasstotal" style="margin-top: 10px;">
			</el-pagination>
			<el-dialog width="50%" title="批量导入" :visible.sync="innerVisible" append-to-body top="0">
				<el-form :rules="Plrules" ref="piliangForm" :model="form" label-width="80px">
					<el-form-item label="批量信息" prop="T_text">
						<el-input type="textarea" rows='15' placeholder="批量输入SN|id,使用回车键或逗号分隔(注:回车键与逗号不能同时使用)" v-model="form.T_text"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit('piliangForm')">立即导入</el-button>
						<el-button @click="innerVisible = false">取消</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog width="30%" title="编辑" :visible.sync="BianjiVisible" append-to-body top="0">
				<el-form :rules="BJrules" ref="BianjiForm" :model="bianjiForm" label-width="80px">
					<el-form-item label="编辑T_id" prop="T_id">
						<el-input type="number" placeholder="请输入内容完成修改" v-model="bianjiForm.T_id"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onBjSubmit('BianjiForm')">立即修改</el-button>
						<el-button @click="BianjiVisible = false">取消</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</el-dialog>

		<el-dialog title="批量导入反馈信息" width="50%" :visible.sync="WinModel" top="0" @close="SubWinModel()" :close-on-click-modal="false">
			<div style="display: flex;flex-direction: column;max-height:500px;">
				<div style="flex: 1;overflow-y: scroll;border-bottom: 1px solid #EBEEF5;">
					<el-table :data="winData" border :row-class-name="tableRowClassName">
						<el-table-column property="winSn" label="SN"></el-table-column>
						<el-table-column property="winId" label="ID"></el-table-column>
						<el-table-column property="winstate" label="导入状态"></el-table-column>
						<el-table-column property="winhint" label="提示"></el-table-column>
					</el-table>
				</div>
				<div style="margin-top: 20px;display: flex;justify-content: flex-end">
					<span slot="footer" class="dialog-footer">
						<el-button type="primary" @click="SubWinModel">关闭窗口</el-button>
					</span>
				</div>
			</div>
		</el-dialog>

		<div class="ClassifyList3">
			<el-pagination background @current-change="handleCurrentChange" :page-size="Pages.page_z"
				layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		DeviceList, //分类列表
		DeviceAdd, //新增分类
		DeviceUp, //编辑分类
		DeviceDel, //设备分类-删除
		DeviceClassList, //设备分类-设备管理-设备列表
		DeviceClassDel, //设备分类-设备管理-设备列表删除
		DeviceClassAllAdd ,//批量添加
		DeviceClassUp//修改T_id
	} from '../../../api/DeviceClassify.js'
	export default {
		data() {
			return {
				ifyList: false, //编辑添加弹框
				ifyShebei: false, //设备列表弹窗
				innerVisible: false, //第二层批量导入弹窗
				BianjiVisible: false, //第二层批量编辑弹窗
				WinModel: false, //批量导入反馈
				winData: [],
				titles: '',
				DeviceClass: {
					T_class: null, //分类的Id
					T_sn: '', //设备列表的搜索
					page: 1,
					page_z: 5,
				},
				DeviceClasstotal: 5, //设备列表总条数
				form: {
					name: '',
					Id: null
				},
				bianjiForm:{
					Id:null,
					T_id:null
				},
				rules: {
					name: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur'
					}]
				},
				Plrules: { //批量SN验证
					T_text: [{
						required: true,
						message: '请输入SN',
						trigger: 'blur'
					}]
				},
				BJrules: { //编辑
					T_id: [{
						required: true,
						message: '请输入T_id',
						trigger: 'blur'
					}]
				},
				Pages: {
					page: 1,
					page_z: 10,
					T_name: null
				},
				total: 30, //总条数
				tableData: [],
				gridData: [],
			}
		},
		mounted() {
			this.PostDeviceList()
		},
		methods: {
			PostDeviceList() { //分类列表
				DeviceList(this.Pages).then(res => {
					if (res.data.Code == 200) {
						this.tableData = res.data.Data.List
						this.total = res.data.Data.Num
					}
				})
			},
			PostDeviceAdd() { //添加分类
				DeviceAdd({
					T_name: this.form.name
				}).then(res => {
					if (res.data.Code == 200) {
						this.PostDeviceList()
						this.ifyList = false
						this.$message({
							type: 'success',
							message: '添加分类成功'
						});
					}
				})
			},
			PostDeviceUp() { //编辑分类
				DeviceUp({
					T_name: this.form.name,
					Id: this.form.Id
				}).then(res => {
					console.log('编辑分类', res)
					if (res.data.Code == 200) {
						this.PostDeviceList()
						this.ifyList = false
						this.$message({
							type: 'success',
							message: '编辑分类成功'
						});
					}
				})
			},
			PostDeviceDel(e) { //分类删除
				DeviceDel({
					Id: e
				}).then(res => {
					console.log('分类删除', res)
					if (res.data.Code == 200) {
						this.PostDeviceList()
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
				})
			},
			PostDeviceClassList() { ////设备分类-设备管理-设备列表
				DeviceClassList(this.DeviceClass).then(res => {
					console.log('设备列表', res)
					if (res.data.Code == 200) {
						this.gridData = res.data.Data.List
						this.DeviceClasstotal = res.data.Data.Num
					}
				})
			},
			searchDCValue() { //设备分类-设备管理-设备列表搜索
				this.DeviceClass.page = 1
				this.gridData = []
				this.PostDeviceClassList()//设备分类-设备管理-设备列表
			},
			searchValue() { //设备分类搜索
				this.Pages.page = 1
				this.tableData = []
				this.PostDeviceList()
			},
			Newzeng() { //新增分类
				this.ifyList = true
				this.form.name = ''
				this.titles = '新增分类'
			},
			Compiles(e) { //编辑
				console.log(e)
				this.form.Id = e.Id
				this.form.name = e.T_name
				this.ifyList = true
				this.titles = '编辑分类'
			},
			Lists(e) { //设备管理
				this.DeviceClass.T_class = e
				this.ifyShebei = true
				this.PostDeviceClassList()
			},
			DeviceClassDelBianji(e){
				this.bianjiForm.Id = e.Id
				this.bianjiForm.T_id = e.T_id
				this.BianjiVisible = true
			},
			DeviceClassDelDeletes(e) {
				console.log('删除', e)
				this.$confirm('此操作将删除该分类, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					DeviceClassDel({
						Id: e
					}).then(res => {
						if (res.data.Code == 200) {
							this.PostDeviceClassList()
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			submitForm(formName) { //提交编辑或者新增按钮
				var _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) { //验证通过
						if (_this.titles === '新增分类') {
							_this.PostDeviceAdd()
						} else if (_this.titles === '编辑分类') {
							_this.PostDeviceUp()
						}
					} else {
						return false;
					}
				});
			},
			onBjSubmit(BianjiForm){
				this.$refs[BianjiForm].validate((valid) => {
					if (valid) {
						console.log('验证通过',this.bianjiForm)
						DeviceClassUp({
							Id:this.bianjiForm.Id,
							T_id:this.bianjiForm.T_id
						}).then(res=>{
							console.log('sss',res)
							if(res.data.Code===200 && res.data.Msg==='ok!'){
								this.PostDeviceClassList()
								this.$message({
									type:'success',
									message:'修改成功'
								})
								this.BianjiVisible = false
							}else{
								this.$message({
									type:'error',
									message:'修改失败'
								})
							}
						})
					}else{
						this.$message({
							type:'error',
							message:'抱歉，请输入内容后在提交哦'
						})
					}
				})
			},
			onSubmit(piliangForm) { //批量导入的提交按钮
				var _this = this
				this.$refs[piliangForm].validate((valid) => {
					_this.winData = []
					if (valid) {
						var Arr = []
						if (this.form.T_text.indexOf(',') !== -1) {
							console.log('存在英文逗号')
							Arr = this.form.T_text.split(',')
						} else if (this.form.T_text.indexOf('，') !== -1) {
							console.log('存在中文逗号')
							Arr = this.form.T_text.split('，')
						} else {
							console.log('存在换行')
							Arr = this.form.T_text.split('\n')
						}
						Arr.forEach(function(sn) {
							DeviceClassAllAdd({
								T_class: _this.DeviceClass.T_class,
								T_sn: sn.split('|')[0],
								T_id: sn.split('|')[1]
							}).then(res => {
								console.log('批量', res)
								if (res.data.Code == 200) {
									_this.winData.push({
										winSn: sn.split('|')[0],
										winId: sn.split('|')[1],
										winstate: '导入成功',
										winhint: res.data.Msg
									})
								} else {
									_this.winData.push({
										winSn: sn.split('|')[0],
										winId: sn.split('|')[1],
										winstate: '导入失败',
										winhint: res.data.Msg
									})
								}
							})
						})
						_this.WinModel = true
					} else {
						console.log('验证未通过', Arr);
						return false;
					}
				});
			},
			tableRowClassName(obj) {
				if (obj.row.winstate === "导入失败") {
					return 'warning-row';
				} else if (obj.row.winstate === "导入成功") {
					return 'success-row';
				}
				return '';
			},
			//批量导入反馈信息确定按钮
			SubWinModel(){
				this.WinModel = false
				this.innerVisible = false
				this.PostDeviceClassList()//设备分类-设备管理-设备列表
			},
			Deletes(e) { //删除
				this.$confirm('此操作将删除该分类, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.PostDeviceDel(e)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			handleCurrentChange(val) { //分类列表当前页
				this.Pages.page = val
				this.PostDeviceList()
			},
			DeviceClassChange(val) { //分类列表-设备列表-当前页
				this.DeviceClass.page = val
				this.PostDeviceClassList()
			},
		}
	}
</script>

<style>
	@import url("../../../assets/css/el-dialog.css");

	.el-table .warning-row {
		color: red;
	}

	.el-table .success-row {
		color: #409EFF;
	}

	.ClassifyList {
		background: #fff;
		padding: 10px;
	}

	.ClassifyList1 {
		display: flex;
		align-items: center;
	}

	.ClassifyList1_1 {
		display: flex;
		align-items: center;
		min-width: 500px;
	}

	.ClassifyList2 {
		margin-top: 15px;
	}

	.ClassifyList3 {
		margin-top: 15px;
	}
</style>
